require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import "bootstrap";
import "../stylesheets/application";
import "daterangepicker";
import $ from "jquery";
import moment from "moment";

global.$ = jQuery;
global.moment = moment;

// Your additional JavaScript
document.addEventListener('turbolinks:load', function() {
  const apiSourceRadios = document.querySelectorAll('input[name="api_source"]');
  const lettingAgencySelect = document.getElementById('letting_agency');
  const loadingIndicator = document.getElementById('loading-indicator');
  const reportForm = document.getElementById('report-form');

  function updateLettingAgencyOptions() {
    const selectedApiSource = document.querySelector('input[name="api_source"]:checked').value;
    const lettingAgencies = selectedApiSource === 'Reapit' ? reapitAgencies : smeAgencies;

    lettingAgencySelect.innerHTML = '';
    if (lettingAgencies.length > 0) {
      lettingAgencies.forEach(([name, id]) => {
        const option = document.createElement('option');
        option.value = id;
        option.textContent = name;
        lettingAgencySelect.appendChild(option);
      });
    } else {
      const option = document.createElement('option');
      option.value = '';
      option.textContent = 'No letting agencies available';
      lettingAgencySelect.appendChild(option);
    }
  }

  apiSourceRadios.forEach(radio => {
    radio.addEventListener('change', updateLettingAgencyOptions);
  });

  // Initial population
  updateLettingAgencyOptions();

  // Initialize datepickers
  $('.datepicker1').daterangepicker({
    singleDatePicker: true,
    startDate: moment().subtract(1, 'days'),
    maxDate: moment().subtract(1, 'days'),
    locale: {
      format: 'YYYY-MM-DD'
    }
  });
  $('.datepicker2').daterangepicker({
    singleDatePicker: true,
    startDate: moment().add(1, 'days'),
    minDate: moment().add(1, 'days'),
    locale: {
      format: 'YYYY-MM-DD'
    }
  });

  // Show loading indicator on form submit
  reportForm.addEventListener('submit', function() {
    loadingIndicator.style.display = 'block';
  });
});
